import { useEffect, useState } from "react";
import AppModal from "../../component/CoreLayout/AppModal";
import { CButton, CFormTextarea } from "@coreui/react";
import FormControl from "../../component/FormControl/FormControl";

export default function AddEditReason({ data, show, toggleModal, onSave }) {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    title: "",
    title_en: "",
    description: "",
    description_en: "",
  });

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (formData.id) {
      onSave(formData);
    } else {
      onSave({ ...formData, id: crypto.randomUUID() });
    }
    setFormData({
      title: "",
      title_en: "",
      description: "",
      description_en: "",
    });
    toggleModal();
  };

  useEffect(() => {
    setFormData(data ?? { ...data });
    setIsEdit(data ? true : false);
  }, [data]);

  return (
    <AppModal
      title={isEdit ? "Edit Reason" : "Add Reason"}
      visible={show}
      closeModal={toggleModal}
    >
      <div>
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ""}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ""}
          handlerChange={onChange}
        />

        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description"
          value={formData?.description ? formData.description : ""}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="2"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ""}
          onChange={onChange}
        ></CFormTextarea>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={toggleModal}>
          Cancel
        </CButton>
        <CButton onClick={handleSave} size="sm" style={{ minWidth: 125 }} color="primary">
          Save
        </CButton>
      </>
    </AppModal>
  );
}
