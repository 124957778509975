import AboutBanner from "../../container/AboutUs/Banner";
import Client from "../../container/AboutUs/Client";
import Founder from "../../container/AboutUs/Founder";
import Milestone from "../../container/AboutUs/Milestone";
import Partner from "../../container/AboutUs/Partner";
import BootcampApplicant from "../../container/Applicant/Bootcamp";
import FulltimeApplicant from "../../container/Applicant/Fulltime";
import CareerBanner from "../../container/Career/CareerBanner";
import OpenJob from "../../container/Career/OpenJob";
import BootcampBenefit from "../../container/Bootcamp/BootcampBenefit";
import BootcampData from "../../container/Bootcamp/BootcampData";
import BootcampClass from "../../container/Bootcamp/BootcampClass";
import BootcampInNumbers from "../../container/Bootcamp/BootcampInNumbers";
import CompanyData from "../../container/CompanyData";
import Office from "../../container/ContactUs/Office";
import Employee from "../../container/Employee";
import HomeBanner from "../../container/Home/HomeBanner";
import Testimonial from "../../container/Home/Testimonial";
import CaseStudy from "../../container/Insight/CaseStudy";
import News from "../../container/Insight/News";
import Opty from "../../container/Opty";
import Theme from "../../container/Theme";
import Event from "../../container/Event";
import InsightBanner from "../../container/Insight/Banner";
import Experience from "../../container/Product/Experience";
import ITPro from "../../container/Product/ITPro";
import Solution from "../../container/Product/Solution";
import Request from "../../container/Product/Solution/Request";
// import ProductSolutions from '../../container/ProductSolutions';
import AboutSummary from "../../container/Summary";
import ProductBanner from "../../container/Product/Banner";
import ServiceBanner from "../../container/Product/ServiceBanner";
import Category from "../../container/AboutUs/Client/Category";

const RoutesData = [
  { path: "/company-data", name: "Company Data", element: CompanyData },
  { path: "/employee", name: "Employee", element: Employee },
  { path: "/home/banner", name: "Home Banner", element: HomeBanner },
  { path: "/home/testimoni", name: "Home Testimoni", element: Testimonial },
  { path: "/about-us/banner", name: "About Banner", element: AboutBanner },
  { path: "/about-us/summary", name: "About Summary", element: AboutSummary },
  { path: "/about-us/milestone", name: "About Milestone", element: Milestone },
  { path: "/about-us/founder", name: "About Founder", element: Founder },
  { path: "/about-us/partner", name: "About Partner", element: Partner },
  { path: "/about-us/client", name: "About Client", element: Client },
  { path: "/about-us/category", name: "About Category", element: Category },
  { path: "/career/banner", name: "Career Banner", element: CareerBanner },
  { path: "/career/open-job", name: "Open Job", element: OpenJob },
  { path: "/product/banner", name: "Banner", element: ProductBanner },
  {
    path: "/product/service-banner",
    name: "ServiceBanner",
    element: ServiceBanner,
  },
  { path: "/product/experience", name: "Our Experience", element: Experience },
  { path: "/product/it-pro", name: "IT Pro", element: ITPro },
  { path: "/product/solution", name: "Solution", element: Solution },
  { path: "/product/request", name: "Solution", element: Request },
  // { path: '/product/product-solutions', name: 'Product & Solutions', element: ProductSolutions },
  {
    path: "/bootcamp/bootcamp-benefit",
    name: "Bootcamp Benefit",
    element: BootcampBenefit,
  },
  {
    path: "/bootcamp/bootcamp-class",
    name: "Bootcamp Class",
    element: BootcampClass,
  },
  {
    path: "/bootcamp/bootcamp-data",
    name: "Bootcamp Data",
    element: BootcampData,
  },
  {
    path: "/bootcamp/bootcamp-in-numbers",
    name: "Bootcamp In Numbers",
    element: BootcampInNumbers,
  },
  { path: "/insight/banner", name: "Insight Banner", element: InsightBanner },
  { path: "/insight/case-study", name: "Case Study", element: CaseStudy },
  { path: "/insight/news", name: "News", element: News },
  { path: "/contact-us/office", name: "Office", element: Office },
  {
    path: "/applicant/bootcamp",
    name: "Bootcamp Applicant",
    element: BootcampApplicant,
  },
  {
    path: "/applicant/fulltime",
    name: "Fulltime Applicant",
    element: FulltimeApplicant,
  },
  { path: "/opty", name: "Opty", element: Opty },
  { path: "/theme", name: "Theme", element: Theme },
  { path: "/event", name: "Event", element: Event },
];

export default RoutesData;
