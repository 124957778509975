import { useEffect, useState } from "react";
import Alert from "../../component/Alert";
import { CButton, CCard, CCardBody, CCardHeader } from "@coreui/react";
import EventList from "./EventList";
import AddEditEvent from "./AddEditEvent";
import { getEventListService } from "../../services/event";

export default function Event() {
  const [isShowModal, setIsShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [list, setList] = useState([]);

  const getList = async () => {
    const response = await getEventListService();
    setList(response.data);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage("");
    setErrorMessage("");

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Event</span>
          <CButton
            onClick={() => setIsShowModal(true)}
            size="sm"
            className="float-end"
            color="primary"
          >
            Add Event
          </CButton>
        </CCardHeader>
        <CCardBody>
          <EventList data={list} onResponse={(res) => handleResponse(res)} />
        </CCardBody>
      </CCard>
      <AddEditEvent
        show={isShowModal}
        toggleModal={() => setIsShowModal(false)}
        onResponse={(res) => handleResponse(res)}
      />
    </>
  );
}
