import { useEffect, useState } from "react";
import AppModal from "../../component/CoreLayout/AppModal";
import { addEventService, updateEventService } from "../../services/event";
import InputImage from "../../component/InputImage/InputImage";
import FormControl from "../../component/FormControl/FormControl";
import {
  CButton,
  CFormCheck,
  CFormTextarea,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilTrash } from "@coreui/icons";
import AddEditReason from "./AddEditReason";

export default function AddEditEvent({ data = null, show, toggleModal, onResponse }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [formData, setFormData] = useState({
    banner: "",
    title: "",
    title_en: "",
    time: "",
    time_en: "",
    place: "",
    place_en: "",
    description: "",
    description_en: "",
    agenda_title: "",
    agenda_title_en: "",
    agenda_content: "",
    agenda_content_en: "",
    agenda_image: "",
    is_active: "0",
    reason_detail: [],
  });
  const [reasonDetail, setReasonDetail] = useState([]);

  useEffect(() => {
    setFormData(data ?? { ...data });
    setReasonDetail(data ? data.reason_detail : []);
    setIsEdit(data ? true : false);
  }, [data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = "";

    if (name === "banner" || name === "agenda_image") {
      value = event.target.files[0];
    } else if (name === "is_active") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChangeDescription = (value) => {
    setFormData({ ...formData, description: value });
  };

  const onChangeDescriptionEn = (value) => {
    setFormData({ ...formData, description_en: value });
  };

  const onChangeAgendaContent = (value) => {
    setFormData({ ...formData, agenda_content: value });
  };

  const onChangeAgendaContentEn = (value) => {
    setFormData({ ...formData, agenda_content_en: value });
  };

  const checkInput = () => {
    if (
      formData.banner &&
      formData.title &&
      formData.title_en &&
      formData.time &&
      formData.time_en &&
      formData.place &&
      formData.place_en &&
      formData.description &&
      formData.description_en &&
      formData.agenda_title &&
      formData.agenda_title_en &&
      formData.agenda_content &&
      formData.agenda_content_en &&
      formData.agenda_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clear = () => {
    setFormData({
      banner: "",
      title: "",
      title_en: "",
      time: "",
      time_en: "",
      place: "",
      place_en: "",
      description: "",
      description_en: "",
      agenda_title: "",
      agenda_title_en: "",
      agenda_content: "",
      agenda_content_en: "",
      agenda_image: "",
      is_active: "0",
      reason_detail: [],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    typeof formData.banner !== "string" && formDataObj.append("banner", formData.banner);
    if (formData.id) formDataObj.append("id", formData.id);
    formDataObj.append("title", formData.title);
    formDataObj.append("title_en", formData.title_en);
    formDataObj.append("time", formData.time);
    formDataObj.append("time_en", formData.time_en);
    formDataObj.append("place", formData.place);
    formDataObj.append("place_en", formData.place_en);
    formDataObj.append("description", formData.description);
    formDataObj.append("description_en", formData.description_en);
    formDataObj.append("agenda_title", formData.agenda_title);
    formDataObj.append("agenda_title_en", formData.agenda_title_en);
    formDataObj.append("agenda_content", formData.agenda_content);
    formDataObj.append("agenda_content_en", formData.agenda_content_en);
    typeof formData.agenda_image !== "string" &&
      formDataObj.append("agenda_image", formData.agenda_image);
    formDataObj.append("is_active", formData.is_active ? "1" : "0");

    const rd = reasonDetail.map((rd) => {
      const { title, title_en, description, description_en } = rd;
      if (isNaN(Number(rd.id))) {
        return {
          title,
          title_en,
          description,
          description_en,
        };
      }
      return rd;
    });

    formDataObj.append("reason_detail", JSON.stringify(rd));

    try {
      const response = isEdit
        ? await updateEventService(formDataObj)
        : await addEventService(formDataObj);
      onResponse(response);
      clear();
    } catch (error) {
      onResponse(error);
    } finally {
      setIsSubmitting(false);
      clear();
      toggleModal();
    }
  };

  const handleSaveReason = (data) => {
    setReasonDetail((rd) => {
      return [...rd.filter((i) => i.id !== data.id), data];
    });
  };

  const onEditReason = (data) => {
    setIsShowModal(true);
    setSelectedData(data);
  };

  const onDeleteReason = (id) => {
    setReasonDetail((rd) => rd.filter((i) => i.id !== id));
  };

  return (
    <AppModal
      title={isEdit ? "Edit Event" : "Add Event"}
      visible={show}
      closeModal={toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Banner Image</label>
        <InputImage
          imgSrc={formData?.banner ? formData.banner : ""}
          name="banner"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ""}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ""}
          handlerChange={onChange}
        />

        <CFormTextarea
          className="mb-2"
          label="Time"
          rows="2"
          name="time"
          value={formData?.time ? formData.time : ""}
          onChange={onChange}
          placeholder="Ex: Sabtu|20 Februari 2025|8.00 AM - 12.00 PM"
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Time En"
          rows="2"
          name="time_en"
          value={formData?.time_en ? formData.time_en : ""}
          onChange={onChange}
          placeholder="Ex: Saturday|20 February 2025|8.00 AM - 12.00 PM"
        ></CFormTextarea>

        <CFormTextarea
          className="mb-2"
          label="Place"
          rows="2"
          name="place"
          value={formData?.place ? formData.place : ""}
          onChange={onChange}
          placeholder="Ex: Kempinski Hotel|Ballroom D, West Mall|Jakarta, Indonesia"
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Place En"
          rows="2"
          name="place_en"
          value={formData?.place_en ? formData.place_en : ""}
          onChange={onChange}
          placeholder="Ex: Kempinski Hotel|Ballroom D, West Mall|Jakarta, Indonesia"
        ></CFormTextarea>

        <div className="mb-2">
          <label className="form-label">Desctiption</label>
          <ReactQuill
            value={formData?.description ? formData.description : ""}
            theme="snow"
            onChange={onChangeDescription}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Desctiption En</label>
          <ReactQuill
            value={formData?.description_en ? formData.description_en : ""}
            theme="snow"
            onChange={onChangeDescriptionEn}
          />
        </div>

        <FormControl
          type="text"
          label="agenda_title"
          title="Agenda Title"
          value={formData?.agenda_title ? formData.agenda_title : ""}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="agenda_title_en"
          title="Agenda Title En"
          value={formData?.agenda_title_en ? formData.agenda_title_en : ""}
          handlerChange={onChange}
        />

        <div className="mb-2">
          <label className="form-label">Agenda Content </label>
          <ReactQuill
            value={formData?.agenda_content ? formData.agenda_content : ""}
            theme="snow"
            onChange={onChangeAgendaContent}
            formats={AddEditEvent.formats}
            modules={AddEditEvent.modules}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Agenda Content En</label>
          <ReactQuill
            value={formData?.agenda_content_en ? formData.agenda_content_en : ""}
            theme="snow"
            onChange={onChangeAgendaContentEn}
            formats={AddEditEvent.formats}
            modules={AddEditEvent.modules}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Agenda Image</label>
          <InputImage
            imgSrc={formData?.agenda_image ? formData.agenda_image : ""}
            name="agenda_image"
            changeInputImage={onChange}
          />
        </div>
        <CFormCheck
          className="my-2"
          checked={formData.is_active === "0" || !formData.is_active ? false : true}
          id="is_active"
          name="is_active"
          label="Is Active"
          onChange={onChange}
        />

        <hr />

        <div className="d-flex justify-content-between align-items-center mb-2">
          <label className="form-label">Reason</label>
          <CButton
            onClick={() => setIsShowModal(true)}
            size="sm"
            style={{ minWidth: 125 }}
            color="primary"
          >
            Add Reason
          </CButton>
        </div>
        <CTable bordered responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Title</CTableHeaderCell>
              <CTableHeaderCell scope="col">Action</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {reasonDetail.length ? (
              reasonDetail.map((d, i) => {
                return (
                  <CTableRow key={i}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>{d.title}</CTableDataCell>

                    <CTableDataCell>
                      <div className="table-action-wrapper">
                        <button className="btn-edit" onClick={() => onEditReason(d)}>
                          <CIcon icon={cilPencil} />
                        </button>
                        <button className="btn-delete" onClick={() => onDeleteReason(d.id)}>
                          <CIcon icon={cilTrash} />
                        </button>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                );
              })
            ) : (
              <CTableRow>
                <CTableDataCell colSpan={3} className="text-center">
                  Data Empty
                </CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>
        <AddEditReason
          data={selectedData}
          show={isShowModal}
          toggleModal={() => setIsShowModal(false)}
          onSave={handleSaveReason}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" style={{ minWidth: 125 }} color="primary" onClick={handleSubmit}>
          {isSubmitting ? <CSpinner size="sm" /> : "Save Changes"}
        </CButton>
      </>
    </AppModal>
  );
}

AddEditEvent.modules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

AddEditEvent.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
