import { useState } from "react";
import { deleteEvent, downloadEvent } from "../../services/event";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload, cilPencil, cilTrash } from "@coreui/icons";
import AddEditEvent from "./AddEditEvent";
import DeletePrompt from "../../component/DeletePrompt";

export default function EventList({ data, onResponse }) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteEvent(dataId);
      onResponse(response);
    } catch (error) {
      onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const onEdit = (data) => {
    setIsShowModal(true);
    setSelectedData(data);
  };

  const onDownload = async (id) => {
    try {
      const response = await downloadEvent(id);

      const url = response.data; // Replace with your file URL
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "participant.xlsx"; // Suggested filename
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      onResponse(response);
    } catch (error) {
      onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  function copy() {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    alert("Copied the text: " + copyText.value);
  }

  return (
    <>
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Title</CTableHeaderCell>
            <CTableHeaderCell scope="col">Link</CTableHeaderCell>
            <CTableHeaderCell scope="col">Time</CTableHeaderCell>
            <CTableHeaderCell scope="col">Place</CTableHeaderCell>
            <CTableHeaderCell scope="col">Is Active</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {data?.map((d, i) => {
            return (
              <CTableRow key={i}>
                <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                <CTableDataCell>{d.title}</CTableDataCell>
                <CTableDataCell>
                  <a href={"https://www.indocyber.co.id/event/" + d.slug_en}>
                    https://www.indocyber.co.id/event/{d.slug_en}
                  </a>
                </CTableDataCell>
                <CTableDataCell>{d.time}</CTableDataCell>
                <CTableDataCell>{d.place}</CTableDataCell>
                <CTableDataCell>{d.is_active === "0" ? "Non Active" : "Active"}</CTableDataCell>
                <CTableDataCell>
                  <div className="table-action-wrapper">
                    <button className="btn-edit">
                      <CIcon icon={cilPencil} onClick={() => onEdit(d)} />
                    </button>
                    <button className="btn-delete">
                      <CIcon icon={cilTrash} onClick={() => onDelete(d.id)} />
                    </button>
                    <button className="btn-download" onClick={() => onDownload(d.id)}>
                      <CIcon icon={cilCloudDownload} />
                    </button>
                  </div>
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>

      <AddEditEvent
        data={selectedData}
        show={isShowModal}
        toggleModal={() => setIsShowModal(false)}
        onResponse={(res) => onResponse(res)}
      />

      <DeletePrompt
        show={isShowDeletePrompt}
        toggleModal={() => setIsShowDeletePrompt(false)}
        yes={handleDelete}
      />
    </>
  );
}
