import axiosConfig from "./axios";

export const getEventListService = async () => {
  try {
    const response = await axiosConfig.get("/cms/event/getAllData");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const downloadEvent = async (event_id) => {
  try {
    const response = await axiosConfig.post(`/cms/event/participant/print`, { event_id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteEvent = async (id) => {
  try {
    const response = await axiosConfig.post(`/cms/event/delete`, { id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addEventService = async (data) => {
  try {
    const response = await axiosConfig.post("/cms/event/create", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateEventService = async (data) => {
  try {
    const response = await axiosConfig.post(`/cms/event/update`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
